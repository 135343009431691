@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.mat-toolbar{
    background-color: #17292D !important;
}

.main-text-color{
    color: #C9DDC5 !important;
}
.main-gradient{
    background: rgb(23,41,45);
    background: linear-gradient(180deg, rgba(23,41,45,1) 41%, rgba(23,41,45,1) 41%, rgba(89,139,136,1) 100%);
}